import React, { useContext } from 'react';
import { navigate } from 'gatsby';
import { SessionContext } from 'src/context/SessionContext';
import IncomePage from 'src/px/IncomePage';
import { Page } from '../../components';
import { $t } from 'src/utils';
import { goTo } from 'src/utils/links';
import { useExplorer } from 'src/hooks/useExplorer';

const ExplorerIncomeView = ({}) => {
  const { signupUrl } = useContext(SessionContext);
  const { data } = useExplorer();

  const handleNext = () => {
    if (data?.state === 'MA') {
      goTo(signupUrl, { showCountdown: true });
    } else {
      navigate('/explorer/plans');
    }
  };

  return (
    <Page
      hideBanner
      context="Explorer"
      progress={0.45}
      metadata={{
        title: $t({ id: 'explorer/income.meta-title', defaultMessage: 'Explore Health Plans' }),
        description: $t({
          id: 'explorer/income.meta-description',
          defaultMessage: 'Get covered for 2023',
        }),
        image: '/img/og/explorer.png',
      }}
    >
      <IncomePage flow="Explorer" onNext={handleNext} />
    </Page>
  );
};

export default ExplorerIncomeView;
